var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.schedule,
      looading: _vm.dataLoading
    },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nTime(text)) + "\n  ")
          ])
        }
      },
      {
        key: "sessionLength",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.floatToMinutes(text)) + "\n  ")
          ])
        }
      },
      {
        key: "sessionCost",
        fn: function(text) {
          return _c("div", {}, [_vm._v("€" + _vm._s(_vm.formatMoney(text)))])
        }
      },
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            {},
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/teams/" + record.team_id + "/session/" + record.id
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { block: "", size: "small", type: "primary" }
                    },
                    [_vm._v("\n        Manage\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }