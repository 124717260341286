var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.members,
      loading: _vm.dataLoading
    },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nTime(text)) + "\n  ")
          ])
        }
      },
      {
        key: "sessionLength",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.floatToHour(text)) + " Hours\n  ")
          ])
        }
      },
      {
        key: "dob",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nFormat(text)) + "\n  ")
          ])
        }
      },
      {
        key: "created_at",
        fn: function(text) {
          return _c("div", {}, [
            _vm._v("\n    " + _vm._s(_vm.nFormat(text)) + "\n  ")
          ])
        }
      },
      {
        key: "fullAddress",
        fn: function(text, record, index) {
          return _c("div", {}, [
            record.address_1
              ? _c("span", [_vm._v(_vm._s(record.address_1))])
              : _vm._e(),
            record.address_2
              ? _c("span", [_vm._v(", " + _vm._s(record.address_2))])
              : _vm._e(),
            record.town
              ? _c("span", [_vm._v(", " + _vm._s(record.town))])
              : _vm._e(),
            record.region
              ? _c("span", [_vm._v(", " + _vm._s(record.region))])
              : _vm._e(),
            record.postcode
              ? _c("span", [_vm._v(", " + _vm._s(record.postcode))])
              : _vm._e()
          ])
        }
      },
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            {},
            [
              _c(
                "router-link",
                { attrs: { to: "/club/member/" + record.user_id } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { block: "", size: "small", type: "primary" }
                    },
                    [_vm._v("\n        View Profile\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }