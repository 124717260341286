var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Add/Remove Team Members" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 18 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Enter members email" },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword"
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchEmails }
                },
                [_vm._v("Search")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._l(_vm.members, function(member) {
            return _c(
              "a-row",
              { key: member.user_id, attrs: { type: "flex", gutter: 4 } },
              [
                _c("a-col", { attrs: { span: 6 } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(member.first_name) +
                      " " +
                      _vm._s(member.last_name) +
                      "..\n      "
                  )
                ]),
                _c("a-col", { attrs: { span: 12 } }, [
                  _vm._v("\n        " + _vm._s(member.work_email) + "\n      ")
                ]),
                _c(
                  "a-col",
                  { staticClass: "gx-text-right", attrs: { span: 6 } },
                  [
                    !member.ctm_id || member.ctm_status === "inactive"
                      ? _c(
                          "a-button",
                          {
                            staticClass: "gx-btn-cyan",
                            attrs: { block: "", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.updateTeamMember(member.id, "create")
                              }
                            }
                          },
                          [_vm._v("\n          Add to Team\n        ")]
                        )
                      : _vm._e(),
                    member.ctm_status === "active"
                      ? _c(
                          "a-button",
                          {
                            staticClass: "gx-btn-danger",
                            attrs: { type: "danger", block: "", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.updateTeamMember(member.id, "remove")
                              }
                            }
                          },
                          [_vm._v("\n          Remove Member\n        ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.members.length < 1 && _vm.error_msg
            ? _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { staticClass: "gx-text-right", attrs: { xs: 18 } },
                    [
                      _vm._v(
                        "\n        This Member not found in club member lists\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }