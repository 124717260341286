var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _vm.teamLoading === "OK"
        ? _c(
            "div",
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("h1", [_vm._v(_vm._s(_vm.team.team_name))])
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { xl: 12, lg: 24, md: 24, xs: 24, sm: 24 } },
                    [
                      _c(
                        "a-card",
                        {
                          staticClass: "gx-card-table-full gx-table-responsive",
                          attrs: { title: "Team Schedule" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "extra" }, slot: "extra" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.openScheduleModal }
                                },
                                [
                                  _vm._v(
                                    "\n              New Session\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("team-schedule-table", {
                            ref: "schedule_list",
                            attrs: { "club-id": _vm.team.id }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xl: 12, lg: 24, md: 24, xs: 24, sm: 24 } },
                    [
                      _c(
                        "a-card",
                        {
                          staticClass: "gx-card-table-full gx-table-responsive",
                          attrs: { title: "Club Members in this team" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "extra" }, slot: "extra" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.openMemberModal }
                                },
                                [
                                  _vm._v(
                                    "\n              Add Member\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("team-members-table", {
                            ref: "members_list",
                            attrs: { "team-id": _vm.team.id }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("schedule-modal", {
                attrs: {
                  visible: _vm.scheduleModalVisible,
                  "team-id": _vm.team.id
                },
                on: { close: _vm.closeScheduleModal }
              }),
              _c("new-team-member-modal", {
                attrs: {
                  "team-id": _vm.team.id,
                  visible: _vm.addMemberModalVisible
                },
                on: { close: _vm.closeMemberModal }
              })
            ],
            1
          )
        : _c("n-section-loading", {
            attrs: { title: "Loading team details..." }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }